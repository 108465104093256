a {
  color: @text-color;
}

a:hover {
  color: @color-primary;
}

.link {
  color: @color-primary;
}

.link:hover {
  text-decoration: underline;
}

.u-link {
  color: @color-primary;
}

.u-link:hover {
  text-decoration: underline;
}
#root {
  min-height: 100%;
}
.anticon {
  vertical-align: middle;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1384px;
  }
}
@media (max-width: 1200px) {
  .card-list-profile.fixed {
    position: unset !important;
    top: 2em;
    width: 100% !important;
  }
}
@media (max-width: 1600px) {
  .ant-menu-inline-collapsed {
    width: 79px !important;
  }
  .block-mobile,
  .card-profile-conective .social__title {
    display: block !important;
  }
  .card-profile-conective .social__title .action-group-right {
    justify-content: space-between;
  }
  .class-detail-left {
    margin-bottom: 1.75em !important;
  }
  .block-mobile .ant-card-meta {
    margin-bottom: 1em;
  }
  .block-mobile .ant-card-meta {
    max-width: 100% !important;
  }
  .block-mobile .btn-group-right .ant-btn {
    margin-bottom: 0.5em;
  }
  .style-list .block-mobile .btn-group-right .anticon-pushpin {
    position: absolute;
    top: 1em;
    right: 1em;
  }
}
@media (max-width: 992px) {
  .menu-index-item-mobile {
    display: block !important;
  }
  .share-exam .site-button-ghost-wrapper {
    display: flex !important;
  }
  .page-exam .social__title,
  .share-exam .social__title,
  .screen-class .social__title,
  .card-class-detail .social__title {
    display: block !important;
  }
  .card-profile-conective .social__title .site-button-ghost-wrapper {
    display: grid !important;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  .page-exam .action-group-right,
  .card-class-detail .social__title {
    justify-content: space-between;
  }
  .card-doughnut .box-exam-chart-point {
    display: block !important;
  }
  .card-doughnut .legend-doughnut {
    width: 100%;
  }
  .fixed-visible .menu-index-item {
    top: 0 !important;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.25) !important;
    height: 100% !important;
    left: 0 !important;
  }
  .fixed-visible .menu-index-item .x-card {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .fixed-unvisible .menu-index-item {
    position: inherit !important;
    display: none;
  }
}
@media (min-width: 769px) {
  .info-profile .ant-card-body {
    min-height: 29.5em;
  }
}
.logo-vnu-dk {
  position: absolute;
  left: 32px;
}
.ant-layout-content {
  overflow: hidden;
  word-wrap: break-word;
}
@media (max-width: 768px) {
  .logo-vnu-dk {
    position: initial !important;
  }
  .rg-vnu-header {
    display: block !important;
  }
  .statistic-card-info {
    display: block !important;
  }
  main.ant-layout-content {
    padding: 12px 0px !important;
    margin: 0px !important;
  }
  span.anticon.anticon-menu-unfold.trigger {
    visibility: hidden;
  }
  .ant-menu-inline-collapsed {
    width: 49px !important;
  }
  .ant-layout.site-layout {
    margin-left: 50px !important;
  }
  .ant-layout-sider {
    flex: 0 0 50px !important;
    max-width: 50px !important;
    min-width: 50px !important;
    width: 50px !important;
  }
  .social__title,
  .action-group-right {
    display: block !important;
  }
  .site-button-ghost-wrapper {
    display: grid;
    margin-right: 0px !important;
  }
  .site-button-ghost-wrapper.sort-box {
    display: block;
    width: fit-content;
    margin-top: 0.5em;
  }
  .card-class-detail .ant-card-head-title {
    padding: 20px 0 !important;
  }
  .ant-modal-centered .ant-modal {
    max-width: 95%;
  }
  .social__title .action-group-right {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .statistic-card {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    min-height: auto !important;
  }
  .card-info-profile {
    min-height: auto !important;
  }
  .search-or-add-profile {
    .btn-add-profile {
      width: 100%;
      margin-top: 6px;
    }
  }
  .btn-request-user {
    width: 100%;
    margin-top: 6px;
  }
}
@media (max-width: 576px) {
  .social__title .action-group-right,
  .site-button-ghost-wrapper.sort-box {
    display: block !important;
  }

  .site-button-ghost-wrapper,
  .share-exam .site-button-ghost-wrapper {
    margin-bottom: 0.5em;
    display: grid !important;
  }
}
.menu-index-item-mobile {
  display: none;
  font-size: 2em;
}
.menu-index-fixed {
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 1000;
}
.bg-primary {
  color: @white;
  background: @primary-color;
  border-color: @primary-color;
}
.bg-primary {
  background: @primary-color !important;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-menu-item-selected,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after,
#components-layout-demo-custom-trigger .trigger:hover,
a:active,
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: @primary-color;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: unset;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .ant-layout-header.site-layout-background {
  background: @white;
}
.ant-layout-header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  margin: 0 auto 0 0 !important;
}
.ant-layout-sider .logo {
  border: 1px solid @other-1-border;
  border-right: unset;
  height: 64px;
}
.ant-layout-sider .logo img {
  height: 32px;
  margin: 16px;
}
.ant-layout-sider {
  background: @white;
  border-right: 1px solid #f0f0f0;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}
.ant-menu-inline.menu-sidebar .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}
.ant-menu-inline.menu-sidebar .ant-menu-item {
  margin-top: 0px;
}
.ant-menu-inline.menu-sidebar > .ant-menu-item {
  height: 53px;
  line-height: 53px;
}
.ant-menu-inline.menu-sidebar .ant-menu-item {
  border-bottom: 1px solid @other-1-border;
}
.sidebar-footer-company {
  padding-left: 24px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}
.ant-menu-item-selected a {
  color: @primary-color;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100%);
}
.ant-layout {
  background: @background-color-light;
}
.header-sp {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid @other-1-border;
}
.menu-top-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.menu-top-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.menu-top-right.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected {
  color: unset;
  border-bottom: unset !important;
}
.menu-top-right li.ant-menu-item {
  border-bottom: unset !important;
}
.menu-top-right {
  color: @text-body-light;
}
.ant-menu-item .anticon {
  font-size: 16px;
}
.logo-collapsible,
.ant-layout-sider-collapsed .logo-desk,
.ant-layout-sider-collapsed .sidebar-footer-company {
  display: none;
}
.ant-layout-sider-collapsed .logo-collapsible {
  display: block;
}
.logo-collapsible {
  margin: 16px auto !important;
}
.title-classes {
  font-size: 24px;
  margin: 0 auto 0 0 !important;
  font-weight: 500;
}
.title-classes-light {
  font-size: 20px;
}
.title-classes-small {
  font-size: 16px;
}
.f-s-m {
  font-size: 1em;
}
.f-s-xl {
  font-size: 1.75em;
}
.f-s-xxl {
  font-size: 2em;
}
.f-s-sm {
  font-size: 0.75em;
}
.f-s-md {
  font-size: 1.15em;
}
.site-button-ghost-wrapper {
  padding: 4px 4px;
  background: @other-1-border;
  border-radius: 4px;
}
.no-border {
  background: transparent;
  border: unset;
}
.site-button-ghost-wrapper .ant-btn {
  font-weight: 500;
  background: transparent;
  border: unset;
}
.site-button-ghost-wrapper .ant-btn.active {
  border-radius: 4px;
  background: @white;
}
.site-badge-count .ant-badge-count {
  background-color: @color-secondary;
  color: @white;
  box-shadow: 0 0 0 1px @color-secondary inset;
  margin-left: 5px;
}
.social__title,
.action-group-right {
  display: flex;
  align-items: center;
}
.default-color {
  color: @text-body-light;
}
.social__header .ant-btn {
  border-radius: 4px;
}
.align-items-center {
  align-items: center;
}
.default-button {
  background-color: @white !important;
  border: unset;
}
hr.line {
  border-top: 1px solid @grey-2;
}
a.item-menu-dropdown {
  display: flex;
  align-items: center;
}
.tab-cus.tab-content {
  background: transparent;
  border: transparent;
}
.tab-cus.tab-content .tab-pane {
  padding: 0px;
}
.x-card {
  background: @white;
  /* Grey / grey-3 */

  border: 1px solid @other-1-border;
  box-sizing: border-box;
  /* Elevation 2 */

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}
.x-card .ant-card-actions {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ant-btn-dangerous.ant-btn-primary {
  background-color: @color-danger !important;
  border-color: @color-danger !important;
}
.group-label-exam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: @black;
}
.btn-info {
  background-color: @color-secondary;
  border-color: @color-secondary;
  color: @white;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #00ccd6cc !important;
  border-color: #00ccd6cc !important;
  color: @white !important;
  box-shadow: 0 0 0 0.2rem rgb(89 171 195 / 12%) !important;
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: @white;
  background: #ff7875 !important;
  border-color: #ff7875 !important;
}
.extra-action .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: @color-danger;
  background-color: unset;
}
.extra-action .ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}
.x-card.card-classes .ant-card-body {
  min-height: 10rem;
  padding: 12px 24px 24px;
  padding-bottom: 0px !important;
}
.card-classes .tags {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}
.primary {
  color: @primary-color;
}
.secondary {
  color: @color-secondary;
}
.bg-secondary {
  background-color: #00ccd6 !important;
}
.form-request .ant-btn,
.form-request input {
  border-radius: 8px;
  max-width: 24em;
  width: 100%;
}
.text-auto {
  margin: auto;
}
.flex {
  display: flex;
}
.flex-content-evenly {
  justify-content: space-evenly;
}
.btn-gr-join .ant-btn {
  border-radius: 4px;
}
.ant-btn.primary {
  background: @other-1-bg;
  border: unset;
}
.danger {
  color: @color-danger;
}
.page-regiter,
.page-login {
  background: url('../../themes//default/bg-page-login.png');
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.login-page img {
  height: 3.125rem;
  padding: 0.3125rem 0;
}
.auth-form {
  position: relative;
  max-width: 465px;
  color: #252b2f;
  margin: 0 auto 3.125rem;
  padding: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  background-color: @white;
}
.auth-form.auth-form-full {
  max-width: 95%;
}
.page-register-delivery .auth-form.auth-form-dkdt {
  max-width: 1000px !important;
}
.page-register-delivery .auth-form {
  max-width: 80% !important;
}
.mt-6 {
  margin-top: 5rem;
}
.auth-form__inner-wrap {
  padding: 2rem;
}
.auth-form__header {
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.25rem;
}
.auth-form__header-welcome {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.875rem !important;
  line-height: 1.5;
}
.btn-gr-mxh {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.social-auth {
  width: 48%;
  padding: 0.75rem 2.5rem;
  text-align: center;
  font-size: 1rem !important;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  border: 1px solid @other-1-border;
  justify-content: center;
  border-radius: 8px;
}

/* .social-auth--fb {
    background-color: #3b5998;
} */
.social-auth span {
  margin: 0 0.25rem;
}
span.font-face-lt {
  font-size: 18px !important;
}
.social-auth-gg .font-face-lt {
  color: #252b2f;
}
.or-line {
  display: block;
  margin-bottom: 1.875rem;
}
.auth-form .or__line {
  padding-top: 1rem;
}
.or__line {
  display: block;
  text-align: center;
  line-height: 0;
  border-bottom: 1px solid #a7b0b7;
}

.or__text {
  display: inline;
  padding: 0 10px;
  background: @white;
  color: #666e7e;
  text-transform: lowercase;
  font-size: 16px !important;
}
.auth-form__switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: #f2f7fd;
}
.auth-form__switch .btn {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: auto;
  margin: 0;
}
.btn--outline-default {
  border: 2px solid #39c1ce;
  color: @white !important;
  border-radius: 15px;
  border-radius: 2.815rem;
  background-color: #39c1ce;
}
.btn--default {
  width: 100%;
  color: @white !important;
  background-color: #13499f;
}
.text-center {
  text-align: center;
  margin: auto;
}
.form-login .input-group input {
  height: 45px;
}
.form-login .input-group-text {
  padding: 0.75rem 1rem;
}
.text-center span.font-face-lt {
  margin: auto;
}
.auth-form__forgot-password {
  text-align: right;
}
.auth-form label {
  font-size: 16px !important;
  color: #666e7e;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.icon-loading {
  height: 18px;
  object-fit: contain;
}
.form-login .input-group-text i {
  min-width: 1em;
}
.modal-setting-info .ant-modal-close {
  position: absolute;
  top: -55px;
  width: 100%;
  margin: auto;
}
.modal-setting-info .ant-modal-close-x {
  margin: auto;
}
.modal-setting-info .ant-modal-close:focus,
.modal-setting-info .ant-modal-close:hover {
  outline: unset;
}
.modal-setting-info .ant-modal-close-x i {
  font-size: 1.5rem;
  color: @white;
}
.ant-modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.modal-setting-info .ant-modal-header {
  border-bottom: unset;
}
.ant-modal-content {
  background: @white;
  border: 1px solid @other-1-border;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}
.modal-setting-info input,
.btn-form {
  height: 46px;
  border-radius: 8px;
}
.modal-setting-info .ant-picker,
.profile-form .ant-picker {
  width: 100%;
  border-radius: 8px;
}
.modal-setting-info .ant-picker input,
.profile-form .ant-picker input {
  height: 44px;
}
.profile-form.profile-form-view .ant-picker-input > input[disabled],
.profile-form.profile-form-view .ant-input[disabled],
.profile-form.profile-form-view .ant-picker.ant-picker-disabled,
.profile-form.profile-form-view
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.profile-form.profile-form-view .ant-input-affix-wrapper-disabled {
  background: none;
  color: rgba(0, 0, 0, 0.85);
}
.text-right {
  float: right;
}
.cursor {
  cursor: pointer;
}
.ant-modal-mask,
.ant-drawer-mask {
  background-color: #000000cc;
}
.sort-box .anticon {
  font-size: 1.25em;
}
.space-around {
  justify-content: space-around;
}
.card-gv .anticon-user {
  vertical-align: text-top;
}
.card-gv .ant-collapse {
  background: @white;
  border: 1px solid @other-1-border;
  box-sizing: border-box;
  border-radius: 8px;
}
.card-gv .ant-collapse-content,
.card-gv .ant-collapse-content-box {
  border-radius: 0 0 8px 8px !important;
  border-top-color: @other-1-border;
}
.card-gv .ant-collapse-item {
  border-bottom: unset;
}
.align-center {
  align-items: center;
}
.b-r-4 {
  border-radius: 4px;
}
.b-r-8 {
  border-radius: 8px;
}
.ant-card-meta-description {
  color: @black;
}
.color-light {
  color: @text-body-light;
}
.primary-light,
.primary-light a {
  background: @color-primary-tag;
  color: @primary-color;
  border: @other-1-bg;
}
/* .primary-light:hover,.primary-light:hover a {
    background: #cee0ff;
    color: #0043A5;
    border: #cee0ff;
} */
.warning-light,
.warning-light a {
  background: #fff2e8;
  color: #ff7a45;
  border: #fff2e8;
}
.success-light,
.success-light a {
  background: #e6fffb;
  color: @color-secondary;
  border: #e6fffb;
}
.flex-column {
  flex-direction: column;
}
.bage-time {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  color: @white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.f-s-l {
  font-size: 1.5em;
}
.space-between {
  justify-content: space-between;
}
.card-exam .ant-card-actions > li {
  margin: 0px;
}
.message-count-down {
  width: 30%;
}
.bg-success {
  background-color: @color-success !important;
  border-color: @color-success;
  color: @white;
}
.bg-form-profile {
  background-color: @white;
  /* padding: 20px 15px; */
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 7%);
}
.card-exam .ant-card-meta {
  max-width: 50%;
  display: flex;
  align-items: center;
}
.card-exam .card-head-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}
.page-exam .ant-divider-horizontal.ant-divider-with-text {
  color: @grey-3;
}
.page-exam .ant-divider-inner-text {
  color: @black;
}
.avatar-img {
  width: 108px;
  height: 108px;
  object-fit: cover;
}
.btn-avatar {
  position: absolute;
  top: 15px;
  right: 15px;
  background: @other-1-border;
  border: 2px solid @white;
  box-sizing: border-box;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: unset !important;
}
.btn-avatar i {
  font-size: 1.25em;
}
.bage-custom {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.statistic-card {
  min-height: 10.5rem;
  justify-content: space-around;
}
.profile-form input,
.profile-form .btn-submit,
.input-default {
  border-radius: 8px;
  height: 46px;
  line-height: 46px;
}
.profile-form .btn-submit {
  line-height: normal !important;
}
.profile-form .ant-form-item-label,
.modal-setting-info .ant-form-item-label {
  text-align: left;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ant-input[disabled] {
  border: 1px solid @grey-2 !important;
  color: @text-body-light;
}
.profile-form .ant-form-item:last-child {
  /* margin-bottom: 0px; */
}
.profile-form .pd-r-0 {
  padding-right: 0px;
}
.flex.text-center {
  justify-content: center;
}
.card-no-pad .ant-card-body {
  padding: 0px;
}
.card-no-pad .ant-list-item {
  padding: 10px 12px;
}
.card-list-profile .ant-btn-link {
  color: @text-body-light;
}
.card-list-profile .ant-btn-link:hover {
  color: @primary-color;
}
.profile-form .ant-input-affix-wrapper {
  border-radius: 8px;
  padding: 0px 11px;
}
.vertical-align-middle {
  vertical-align: middle;
}
.vertical-align-bottom {
  vertical-align: bottom;
}
.legend-doughnut {
  width: 40%;
  list-style: unset;
}
.legend-doughnut .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}
.legend-doughnut li {
  border-bottom: 1px solid @other-1-border;
  padding: 0.5em 0.5em;
}
.pl-0 {
  padding-left: 0px;
}
.card-doughnut .ant-card-body,
.card-info-exam .ant-card-body {
  padding: 10px 24px;
  min-height: 12.9em;
  margin: auto;
}
.card-info-exam .ant-card-body {
  padding-bottom: 2.4rem;
}
.card-doughnut-tl .ant-card-body {
  display: flex;
  align-items: center;
}
.percent-blueprint {
  min-width: 185px;
  display: flex;
  font-size: 0.75rem !important;
}
.blue-print-label {
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px 15px;
}
.blue-print-text {
  font-size: 0.75rem !important;
}
.item-blueprint {
  padding: 5px;
  margin-top: 0.5em;
  cursor: pointer;
  border-bottom: 1px solid @other-1-border;
}
.blueprint-name {
  width: 60%;
}
.parent-blueprint .ant-collapse-header {
  background: @other-1-border !important;
  border-radius: 8px !important;
  font-weight: 450;
  border: unset !important;
}
.box-blueprint,
.parent-blueprint,
.box-blueprint .ant-collapse-content {
  border: unset !important;
}
.box-blueprint,
.parent-blueprint {
  border-radius: 8px !important;
}
.box-blueprint .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 5px !important;
}
.blue-print-label .item-quantity {
  font-weight: bold;
}
.item-quantity {
  min-width: 45px;
}
.box-blueprint {
  background: transparent;
}
.title-radar-box {
  margin: 0 auto 0 0 !important;
}
.multi-blueprint .ant-select-selector {
  border-radius: 4px !important;
}
.option-choice {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid @grey-2;
  max-width: 26px;
}

.wrong.group-choice .option-choice {
  border-color: @color-danger;
  background-color: @color-danger;
  color: @white;
}
.correct.group-choice .option-choice {
  border-color: @color-success;
  background-color: @color-success;
  color: @white;
}
.layout-item .ant-card-body {
  padding: 12px 24px 4px;
}
.index-item {
  float: left;
  cursor: pointer;
}
.menu-index-item {
  position: fixed;
  top: 2em;
  width: 16.5%;
}
.is-invalid-date {
  border-color: #f86c6b;
}
.ant-picker-input input::placeholder {
  color: #5c6873;
  opacity: 1; /* Firefox */
}
.card-classes .anticon.anticon-more {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-classes .anticon.anticon-more.ant-dropdown-trigger.ant-dropdown-open {
  background-color: #00ccd6cc;
  color: @white;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: unset;
}
.list-user-change .ant-avatar-lg {
  border: 1px solid #f0f0f0;
}
.select-option {
  width: 100%;
}
.select-option .ant-select-selector {
  border-radius: 8px !important;
  padding: 6.5px 11px !important;
  height: 42px !important;
}
.select-option .ant-select-selection-item {
  text-align: left;
}
.ant-drawer-close .fa-times-circle.danger {
  font-size: 1.5rem;
}
.ant-drawer-close:focus {
  outline: unset;
}
.avatar-gv .anticon {
  vertical-align: text-top;
}
.exam-near {
  padding: 10px 24px 0px;
  border-top: 1px solid #f0f0f0;
  padding-top: 1em;
  text-align: left;
}
.color-default {
  color: rgba(0, 0, 0, 0.45);
}
.warning {
  color: #ff7a45;
}
.card-body-position .ant-card-body {
  position: relative;
}
.icon-noti-exam {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}
.noti-exam-lst {
  border-radius: 4px;
  min-width: 250px;
}
.noti-exam-lst li a {
  width: 100%;
}
.btn-info a {
  color: @white !important;
}
.view-class .card-classes {
  min-height: 18rem;
}
.card-info-profile {
  min-height: 16em;
}
.black {
  color: @black;
}
.bold {
  font-weight: bold;
}
.hidden {
  display: none;
}
.card-classes {
  animation: fade-in 1s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.flex-end {
  justify-content: flex-end;
}
.option-choice-multi {
  border-radius: 2px !important;
}
.bg-warning {
  background-color: #ff7a45 !important;
}
.vertical-baseline {
  vertical-align: baseline;
}
.width-auto {
  width: auto;
  overflow: hidden;
}
.card-list-profile.fixed {
  position: fixed;
  top: 2em;
  width: 16.5%;
}
.request-right {
  // width: max-content;
  margin: auto;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @white;
}
.site-button-ghost-wrapper .ant-btn {
  color: @text-body-light;
  font-weight: 400;
}
.site-button-ghost-wrapper .ant-btn.active {
  color: @black;
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-link {
  color: @primary-color;
}
.card-class-detail .ant-card-head-title {
  padding: 12px;
}
.card-class-detail .title-classes-light {
  font-size: 16px;
}
.class-detail-left .ant-card-head-title {
  padding: 20px 0;
}
.exam-chart-point {
  // width: 60%;
  width: 250px;
  position: relative;
}
.exam-point {
  position: absolute;
  left: 50%;
  top: 38%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: red;
}
.vertical-text-top {
  vertical-align: text-top;
}
.view-customer .card-info-exam .ant-card-body {
  padding-bottom: 1rem;
}
.view-customer .card-doughnut .ant-card-body,
.view-customer .card-info-exam .ant-card-body {
  min-height: 12.5rem;
}
.file-viewer {
  border-radius: 4px !important;
  border: 1px solid @other-1-border;
  padding: 5px 9px;
}
.wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-x: auto;
}
.img-list-wrap {
  -ms-flex: 1 1;
  flex: 1 1;
}
.img-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.img-list .img-item {
  padding: 1px;
}
.img-list .img-item > img {
  width: 150px;
  height: 150px;
  cursor: pointer;
  object-fit: cover;
}
.file-viewer .wrap::-webkit-scrollbar {
  height: 5px;
}

.file-viewer .wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.file-viewer .wrap::-webkit-scrollbar-thumb {
  background-color: @primary-color;
  outline: 1px solid @primary-color;
}
.card-menu .ant-card-body::-webkit-scrollbar {
  width: 5px;
}
.body-sp::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 10px;
}

.card-menu .ant-card-body::-webkit-scrollbar-track,
.body-sp::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-menu .ant-card-body::-webkit-scrollbar-thumb,
.body-sp::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #1890ff33;
  outline: 1px solid #1890ff33;
}
.winnerpana .ant-image-img {
  width: 80% !important;
}
.bravo-box {
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-pdf__Page__canvas {
  max-width: 100%;
}

.page-controls {
  background: @white;
  opacity: 1;
  text-align: center;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 5%);
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-controls button {
  width: 44px;
  height: 44px;
  background: @white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
  -ms-flex-align: center;
  align-items: center;
}
.page-controls button:focus {
  outline: unset;
}
.file-viewer a,
.ant-btn-secondary .anticon {
  color: @white;
}
.baseline {
  vertical-align: baseline;
}
.content-item p {
  margin-bottom: 0px;
}
.content-item {
  flex: 1;
  padding-top: 3px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}
.regis-form .ant-input-number {
  width: 100%;
  border-radius: 8px;
}
.login-form .ant-input-affix-wrapper .ant-input-prefix .anticon {
  margin-top: -4px;
}
.tags .ant-badge-count {
  min-width: 16px;
  height: 16px;
  padding: 0 0px;
  font-size: 10px;
  line-height: 16px;
  margin-left: 2px;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background: @white;
}
.bg-primary .ant-badge-count {
  color: @primary-color;
}
.bg-success .ant-badge-count {
  color: @color-success;
}
.tags .ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: text-bottom;
}
.popover-tooltip .ant-popover-inner-content {
  display: none;
}
.vertical-align-initial {
  vertical-align: initial;
}
.tags .ant-tag {
  display: flex;
  align-items: center;
}
.password-input label::after {
  content: '';
}
.vertical-align-initial {
  vertical-align: initial;
}
.popover-tooltip .ant-popover-inner-content {
  display: none;
}
.password-input label::after {
  content: '';
}
.card-menu .ant-card-body {
  max-height: 85vh;
  overflow: auto;
}
.page-login.app {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}
.menu-left {
  position: fixed;
  height: 100vh;
}
.guide-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: @white;
  z-index: 10002;
  color: @black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.type-profile
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px;
}
.legend {
  color: @primary-color;
  display: block;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  /* font-size: 21px; */
  font-weight: bold;
  line-height: inherit;
  border: 0;
  /* border-bottom: 1px solid #e5e5e5; */
  background-color: @other-1-bg;
}
.profile-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}
.profile-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 46px;
}
.profile-form
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder,
.profile-form
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 46px;
}
.profile-form .ant-picker {
  padding: 2px 11px 2px;
}
.avatar-uploader .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 100%;
  height: 150px;
}
.color-danger {
  color: red;
}
.form-syll .ant-form-item {
  margin-top: 1em;
}
.filter-tem-right {
  width: 40%;
  float: right;
}
.filter-tem-right-pro {
  width: 300px;
  max-width: 100%;
  float: right;
}
.avatar-uploader img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.profile-ava {
  max-width: 90px !important;
  height: 100px !important;
}
/* .filter-temp .ant-input-affix-wrapper{
    width: 300px;
    float: right;
} */
.filter-vnu .ant-input-affix-wrapper {
  padding: 0px 14px;
  height: 40px;
}
.header-profile-choose {
  border-bottom: 1px solid #e5e5e5;
}
.header-profile-choose {
  padding-bottom: 10px;
}
.step-register {
  background: @other-1-bg;
  padding: 32px 24px;
}
.title-profile {
  font-weight: 400;
  width: 100%;
}
/* .title-profile > span{
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
} */
.temp-item {
  padding: 2px;
}
.selected.temp-item,
.order-info {
  border: 1px solid @color-info-border;
  border-radius: 4px;
}
.selected.temp-item {
  border: 1px solid #5e91dd !important;
}
.step-register .ant-steps-item-finish .ant-steps-icon {
  top: -3px;
  color: @white;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: @color-success;
  border-color: @color-success;
}
label.font-14,
.font-14 {
  font-size: 14px !important;
}
.order-info {
  padding: 32px;
  // width: max-content;
  min-width: 450px;
  margin: auto;
  border: 1px solid @other-1-border;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.03));
  border-radius: 8px;
}
.header-order-info {
  border-bottom: 1px dashed @grey-2;
}
.item-payment {
  padding: 15px 10px;
  background: @color-info-tag;
  border-radius: 4px;
  cursor: pointer;
}
.item-payment.active {
  /* background: #91D5FF; */
  border: 1px solid @color-info-border;
}
.item-payment img {
  width: 100%;
}
.page-not-found {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-kt {
  max-width: 90%;
  max-height: 32px;
}
.info-order {
  min-height: 8.5rem;
}

.auth-form .section {
  padding: 32px;
}
.step-register
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: @primary-color;
}
.step-register .ant-steps-item-process .ant-steps-item-icon {
  border-color: @primary-color;
}
.step-register
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: @primary-color;
}
.btn-create-vnu {
  width: 270px;
  height: auto !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-next-step-vnu {
  width: 200px;
}
.btn-ths {
  background-color: @other-1-bg;
  color: @primary-color;
  height: 40px;
}
.section-body-rg-vnu {
  background-color: @background-color-light;
  border-top: @other-1-border;
  border-bottom: @other-1-border;
}
.section-body-rg-vnu .temp-item {
  background: @white;
  width: 100%;
  padding: 21px 25px;
  /* border: 1.5px solid #0043A5; */
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 8px;
  border: 1px solid @other-1-border;
  box-sizing: border-box;
}
.section-body-rg-vnu .temp-item .title-profile > span {
  width: 300px;
}
.btn-eye-view {
  background-color: @other-1-border;
  padding: 11px 13px;
  border-radius: 50%;
  font-size: 1.25em;
}
.layout-list-temp {
  height: 400px;
  overflow: auto;
}
.section-step1-header {
  padding: 20px 32px !important;
}
.section-step1-header .text {
  padding-top: 5px;
}
.section-step1-header .text .f-s-m {
  font-size: 13px;
}
.profile-selected {
  background: @white;
  border: 1px solid @other-1-border;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 8px;
  padding: 8px 16px;
}
.profile-selected .profile-ava {
  margin-right: 10px;
}
.lephi {
  border-left: 1px solid @other-1-border;
  padding-left: 10px;
  margin-top: 22px;
  float: right;
}
.section-header-step-2 {
  padding-bottom: 10px !important;
}
.body-step-2 {
  padding: 24px 32px;
  background-color: @background-color-light;
}
.content-rule-step2 {
  border: 1px solid @other-1-border;
  box-sizing: border-box;
  background-color: @white !important;
  padding: 16px;
}
.checkbox-step2 {
  background: @white;
  padding: 10px;
}

.box-step-bottom .ant-btn-ghost {
  background: @other-1-border;
  border-color: @other-1-border;
  height: auto !important;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 180px;
}
.btn-step3 {
  min-width: 180px;
  padding: 8px;
  height: auto !important;
}
.modal-confirm-register {
  width: fit-content !important;
}
.icon-error {
  font-size: 3em;
}
.btn-end-register {
  background: @other-1-bg;
  border-color: @other-1-bg;
  height: auto !important;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 450px;
  color: @primary-color;
}
.section-vnu {
  padding: 6px 16px;
  background-color: @white;
}
.section-vnu-le {
  background-color: @background-color-light;
}
.alert-info-px {
  background: @white;
  border: 1px solid @other-1-border;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 8px;
}
.section-vnu
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  position: absolute;
  right: -12px;
  top: 2px;
}
.section-vnu .ant-form-item-label > label::after {
  position: absolute;
  right: -13px;
  top: 0px;
  content: ':';
  display: inline-block;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.section-vnu .ant-form-item-label > label.ant-form-item-required::after {
  right: -22px;
}
.section-vnu .ant-input[disabled] {
  border: none !important;
}
#basic_obj_type {
  background: @other-1-border;
  border: 1px solid @grey-2;
  padding: 6px 16px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
#basic_obj_type .ant-radio-button-wrapper {
  flex: 1;
  text-align: center;
  border: unset !important;
  background: transparent;
  color: @text-body-light;
  height: auto !important;
}
#basic_obj_type .ant-radio-button-wrapper-checked {
  color: @black;
  background: @white;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 6%);
  border-radius: 4px;
}
#basic_obj_type .ant-radio-button-wrapper:not(:first-child)::before {
  background: transparent;
}
#basic_obj_type span.ant-radio-button.ant-radio-button-checked {
  background: @white;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 6%);
  border-radius: 4px;
}
#basic_obj_type .ant-radio-button-wrapper:active,
#basic_obj_type .ant-radio-button-wrapper:focus {
  outline: unset !important;
}
.step-action-vnu {
  padding: 16px;
}
.modal-vnu-quick-view-profile {
  margin-top: 50px;
}
.modal-vnu-quick-view-profile .ant-modal-body {
  padding: 0px;
}
.child-group .blueprint-child {
  padding-left: 2em;
}
.card-doughnut-tl .ant-card-actions > li > span {
  cursor: unset;
}
.ant-back-top {
  bottom: 10px;
  right: 5px;
}
.uppercase input {
  text-transform: uppercase;
}
.back-to-top {
  color: @primary-color;
}

.bookmark-test-taker-item {
  color: @grey-4;
}

.bookmark-test-taker-item.bookmarked-test-taker-item {
  color: @color-warning;
}

@media (max-width: 768px) {
  .auth-form.auth-form-dkdt {
    .section {
      padding: 16px !important;
    }

    .body-step-2 {
      padding: 16px;
      background-color: #fafafa;
    }
    .ant-steps-item-icon {
      width: 24px !important;
      height: 24px !important;
      font-size: 14px !important;
      line-height: 24px !important;
    }
    .ant-steps-item-title {
      font-size: 14px !important;
      line-height: 24px !important;
    }
    .filter-vnu {
      margin-top: 12px;
    }
    .order-info {
      max-width: 100% !important;
      min-width: 100% !important;
    }
    .btn-end-register {
      min-width: 90% !important;
    }
  }
  .page-register-delivery .auth-form.auth-form-dkdt {
    max-width: 100% !important;
  }
  .lephi {
    border: none !important;
    width: 100%;
    text-align: center;
  }
  .step-register-mobile .ant-steps-item-wait .ant-steps-item-title {
    display: none !important;
  }
  .step-register-mobile .ant-steps-item-finish .ant-steps-item-title {
    display: none !important;
  }
  .step-register-mobile .ant-steps-item {
    flex: none !important;
  }
  .step-register-mobile.ant-steps-vertical {
    flex-direction: row;
  }

  .step-register-mobile.ant-steps-vertical
    .ant-steps-item-icon
    .ant-steps-icon {
    top: -2px;
  }
  .step-register-mobile.ant-steps-vertical
    > .ant-steps-item
    .ant-steps-item-content {
    min-height: auto;
  }
}

@media (max-width: 576px) {
  .auth-form.auth-form-dkdt {
    .ant-steps-item {
      flex: auto;
    }
    .ant-steps-item-active {
      .ant-steps-item-title {
        display: block;
      }
    }
    .ant-steps-item-title {
      font-size: 14px !important;
      line-height: 24px !important;
      display: none;
    }
  }
}
